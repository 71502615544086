/** @format */
import React from "react";
import { Layout } from "antd";
import styles from "./index.module.less";
import Header from "@layout/Header";
// import Sider from '@layout/Sider';
// import Footer from '@layout/Footer';

const { Content } = Layout;

interface IProps {}

const BasicLayout: React.FC<IProps> = ({ children }) => {
  return (
    <Layout>
      <Header />
      <Layout>
        <div className={styles.contentBody}>
          <div className={styles.contentView}>
            <Content className={styles.content}>{children}</Content>
          </div>
          {/*<Footer />*/}
        </div>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
