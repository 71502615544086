import "./App.less";
import { Suspense } from "react";
import { Redirect, Switch, Route, BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zhCN from "antd/lib/locale/zh_CN";
import { ConfigProvider } from "antd";
import routers, { Route as IRoute } from "@routes/router";
import LoadingPage from "@components/LoadingPage";
import BasicLayout from "@layout/BasicLayout";
import { flatterRouter } from "@utils/utils";

dayjs.locale("zh-cn");

function App() {
  const renderRoutes = (routes: IRoute[], extraProps = {}) => {
    if (!routes || !routes.length) return null;
    const layoutRoute = routes.filter((item) => !item.noLayout);
    const noLayoutRoute = routes.filter((item) => item.noLayout);

    const RouteCom = (routeData: IRoute[]) => {
      return routeData.map((route, i) => {
        if (route.redirect) {
          return (
            <Redirect
              key={i}
              path={route.path}
              exact={route.exact}
              strict={route.strict}
              to={route.redirect}
            />
          );
        }
        const Com = route.component;
        return Com ? (
          <Route
            key={route.name || i}
            path={route.path ?? ""}
            exact={route.exact}
            strict={route.strict}
            render={(props: any) => {
              document.title = route.name || "在线开发工具平台";
              return <Com {...props} {...extraProps} route={route} />;
            }}
          />
        ) : null;
      });
    };
    return (
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Switch {...extraProps}>
            {/*   无菜单页面    */}
            {RouteCom(noLayoutRoute)}
            {/*   菜单布局页面    */}
            <BasicLayout>
              <Suspense fallback={<LoadingPage />}>
                <Switch>{RouteCom(layoutRoute)}</Switch>
              </Suspense>
            </BasicLayout>
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  };

  return (
    <ConfigProvider locale={zhCN}>
      {renderRoutes(flatterRouter(routers))}
    </ConfigProvider>
  );
}

export default App;
