import styles from "@layout/index.module.less";
import { Layout } from "antd";
// import Logo from "../assets/icons/favicon.png";
import Logo1 from "../assets/icons/icon1.png";
import { Link } from "react-router-dom";

const { Header } = Layout;

const HeaderLayout = () => {
  return (
    <Header className={styles.header}>
      <Link to="/" className={styles.logo}>
        <img
          src={Logo1}
          width="25px"
          height="25px"
          style={{ marginBottom: "4px" }}
          alt=""
        />
        <span style={{ marginLeft: "10px" }}>Develop Tool Online</span>
      </Link>
    </Header>
  );
};

export default HeaderLayout;
