import { Route as IRoute } from '@routes/router';
import dayjs from 'dayjs';
const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

// 检测 8-18位 密码
export const isPassword = (pwd: string) => {
  if (!pwd) return false;
  // eslint-disable-next-line no-useless-escape
  return /^(?!.*\s.*)^(?![0-9]+$)(?![a-zA-Z]+$)(?![!@#$%^&*~`\(\)\*\-\+\=,\.\?;:]+$).{6,18}$/.test(
    pwd,
  );
};

// 手机号校验
export const isMobile = (mobile: string) => {
  const reg = /^1[2-9]\d{9}$/;
  return reg.test(mobile);
};

// 邮箱校验
export const isEmail = (mail: string) => {
  const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  return reg.test(mail);
};

export const fontSize = (res: number): number => {
  const clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (!clientWidth) return 0;
  const fontSize = clientWidth / 1440;
  return res * fontSize;
};

export const flatterRouter = (routers: IRoute[]) => {
  const result: IRoute[] = [];
  for (let i = 0; i < routers.length; i++) {
    const router = routers[i];
    result.push({ ...router });
    if (router.children) {
      result.push(...flatterRouter(router.children || []));
    }
  }
  return result;
};

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let fileResult: any = '';
    reader.readAsDataURL(file);
    //开始转
    reader.onload = () => {
      fileResult = reader.result;
    };
    //转失败
    reader.onerror = (error) => {
      reject(error);
    };
    //结束 resolve
    reader.onloadend = () => {
      resolve(fileResult);
    };
  });
};

export const downloadBlob = (blob: Blob, filename: string) => {
  try {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
  }
};

export const downloadUrl = (url: string, filename: string) => {
  try {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
  }
};

// 计算文件大小
export const fileSizeExchange = (fileSize: number): any => {
  let size: any = 0;
  if (fileSize < 1024) {
    return `${fileSize}B`;
  }
  const fileToKB = fileSize / 1024;
  const fileToMB = fileSize / 1024 / 1024;
  const fileToGB = fileSize / 1024 / 1024 / 1024;
  if (fileToKB < 1024) {
    size = `${Math.floor(fileToKB * 100) / 100}K`;
  } else if (fileToMB < 1024) {
    size = `${Math.floor(fileToMB * 100) / 100}M`;
  } else if (fileToGB < 1024) {
    size = `${Math.floor(fileToGB * 100) / 100}G`;
  }
  return size;
};
export const getTimeDuration = (ms: number) => {
  // @ts-ignore
  const { days, hours, minutes, seconds } = dayjs.duration(ms).$d;
  const res = `${days ? `${days} 天 ` : ''}${hours ? `${hours} 小时 ` : ''}${
    minutes ? `${minutes} 分钟 ` : ''
  }${seconds ? `${seconds} 秒 ` : ''}`;
  return res;
};

export const clearAllStorage = () => {
  localStorage.removeItem('persist:gzdw-web');
};

export const getObjectURL = (file: File) => {
  var url = '';
  if (window.URL !== undefined) {
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL !== undefined) {
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
};
