/** @format */

import React, { lazy } from "react";
import { DotsIcon } from "@components/Icons";

export interface Route {
  path?: string;
  component?: React.ElementType;
  children?: Route[];
  redirect?: string;
  exact?: boolean;
  strict?: boolean;
  // 名称
  name?: string;
  // 无布局模式
  noLayout?: boolean;
  // 显示头部面包屑导航
  hideBreadcrumb?: boolean;
  // 在菜单中显示路由页面标题
  showInMenu?: boolean;
  // 图标
  icon?: React.ElementType;
  // 隐藏菜单栏
  hideMenu?: boolean;
}

const routers: Route[] = [
  {
    path: "/",
    name: "在线开发工具",
    component: lazy(() => import("@pages/home")),
    exact: true,
  },
  {
    path: "/json",
    name: "JSON 编辑器",
    component: lazy(() => import("@pages/json")),
    exact: true,
    showInMenu: true,
    icon: DotsIcon,
  },
  {
    path: "/404",
    name: "404",
    component: lazy(() => import("@pages/home")),
    exact: true,
    hideBreadcrumb: true,
  },
  {
    path: "*",
    redirect: "/404",
    exact: true,
  },
];

export default routers;
