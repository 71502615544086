import React from 'react';
import Icon from '@ant-design/icons';
import { IconProps } from './iconProps';
import { ReactComponent as FoldSvg } from '@/assets/menuIcons/fold.svg';
import { ReactComponent as OrderRestartSvg } from '@/assets/menuIcons/order_restart.svg';
import { ReactComponent as FaultSearchSvg } from '@/assets/menuIcons/fault_search.svg';
import { ReactComponent as HeaderRouteIcon1Svg } from '@/assets/menuIcons/header_route_icon1.svg';
import { ReactComponent as HeaderRouteIcon2Svg } from '@/assets/menuIcons/header_route_icon2.svg';
import { ReactComponent as HeaderRouteIcon3Svg } from '@assets/menuIcons/header_route_icon3.svg';
import { ReactComponent as NoPermission } from '@/assets/common/no_permission.svg';
import { ReactComponent as NoPage } from '@/assets/common/no_page.svg';
import { ReactComponent as RightArrowSvg } from '@/assets/icons/right_arrow.svg';
import { ReactComponent as UploadSvg } from '@/assets/icons/upload.svg';
import { ReactComponent as CloseSvg } from '@/assets/icons/close.svg';
import { ReactComponent as TaskSvg } from '@/assets/icons/task.svg';
import { ReactComponent as StepSuccessSvg } from '@/assets/icons/step_success.svg';
import { ReactComponent as ExportSvg } from '@/assets/icons/export.svg';
import { ReactComponent as UserSvg } from '@/assets/menuIcons/user.svg';
import { ReactComponent as DotsSvg } from '@/assets/menuIcons/dots.svg';
import { ReactComponent as ElectricitySvg } from '@/assets/menuIcons/electricity.svg';
import { ReactComponent as HelpSvg } from '@/assets/icons/help.svg';
import { ReactComponent as NoMessageSvg } from '@/assets/icons/no_message.svg';

export const FoldIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={FoldSvg} />
);

export const OrderRestartIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={OrderRestartSvg} />
);

export const HeaderRouteIcon1: React.FC<IconProps> = (props) => (
  <Icon {...props} component={HeaderRouteIcon1Svg} />
);

export const HeaderRouteIcon2: React.FC<IconProps> = (props) => (
  <Icon {...props} component={HeaderRouteIcon2Svg} />
);

export const HeaderRouteIcon3: React.FC<IconProps> = (props) => (
  <Icon {...props} component={HeaderRouteIcon3Svg} />
);

export const FaultSearchIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={FaultSearchSvg} />
);

export const NoPermissionIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={NoPermission} />
);

export const NoPageIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={NoPage} />
);

export const RightArrowIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={RightArrowSvg} />
);

export const UploadIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={UploadSvg} />
);

export const CloseIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={CloseSvg} />
);

export const TaskIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={TaskSvg} />
);

export const StepSuccessIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={StepSuccessSvg} />
);

export const ExportIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={ExportSvg} />
);

export const UserIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={UserSvg} />
);

export const DotsIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={DotsSvg} />
);

export const ElectricityIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={ElectricitySvg} />
);

export const HelpIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={HelpSvg} />
);

export const NoMessageIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={NoMessageSvg} />
);
